import "./BlogStage.scss";

import {
    Stage,
    StageSize,
    TeaserHeadlineSize,
    TeaserTextProps,
} from "@alphaneun/ui-library";
import dayjs from "dayjs";
import { navigate } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React from "react";

import { Article } from "../types/article";

export interface BlogStageProps {
    article: Article;
}

export const BlogStage: React.FC<BlogStageProps> = ({ article }) => {
    const { title, category, featured_image } = article.data;

    const stageImageContent = (
        <div className="BlogStage__image">
            <GatsbyImage
                alt={title}
                //@ts-ignore
                image={getImage(
                    featured_image.localFile.childImageSharp.gatsbyImageData,
                )}
            />
            <div className="BlogStage__gradient" />
        </div>
    );

    const props: TeaserTextProps = {
        title: category,
        date: dayjs(article.last_publication_date).format("DD.MM.YYYY"),
        headline: title,
        headlineSize: TeaserHeadlineSize.Large,
        wrapHeadlineInLink: true,
        onClickLink: () => navigate("/magazin/" + article.uid),
    };

    return (
        <Stage
            size={StageSize.Default}
            text=""
            image={stageImageContent}
            teaserTextProps={props}
            showAnimation={false}
        ></Stage>
    );
};
