import "./blogindex.scss";

import {
    Section,
    SectionHeadlinePosition,
    SectionType,
    Teaser,
    TeaserHeadlineSize,
    TeaserImagePosition,
    TeaserSize,
} from "@alphaneun/ui-library";
import dayjs from "dayjs";
import { graphql, Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React from "react";

import { BlogStage } from "../../components/BlogStage";
import Layout from "../../components/Layout";
import Pager from "../../components/Pager";
import SEO from "../../components/SEO";
import { Article } from "../../types/article";
import useSiteMetadata from "../../utils/useSiteMetadata";

interface BlogPostsProps {
    data: {
        prismicPages: {
            nodes: Article[];
        };
    };
    pageContext: {
        previousPagePath: string;
        nextPagePath: string;
        pagePathPrefix: string;
        pageNumber: number;
        humanPageNumber: number;
    };
}

const BlogIndex: React.FC<BlogPostsProps> = ({
    data,
    pageContext,
}: BlogPostsProps) => {
    const { url } = useSiteMetadata();
    const pageNumber =
        pageContext.pageNumber > 0 ? "/" + pageContext.humanPageNumber : "";

    return (
        <Layout>
            <SEO
                title="Magazin"
                description="Magazin"
                url={`${url}${pageContext.pagePathPrefix}${pageNumber}`}
                keywords={["magazin"]}
            />
            <div className="BlogIndex">
                <BlogStage article={data.prismicPages.nodes[0]} />

                {data.prismicPages.nodes.length > 1 && (
                    <>
                        <Section
                            className="Section--double"
                            type={SectionType.Default}
                            headline="Unicorn"
                            headlinePosition={SectionHeadlinePosition.Right}
                        >
                            {[
                                data.prismicPages.nodes[1],
                                data.prismicPages.nodes[2],
                            ].map((article, index) => {
                                if (!article) return null;

                                return (
                                    <Link
                                        key={index}
                                        to={article.uid}
                                        className="noStyles"
                                    >
                                        <Teaser
                                            date={dayjs(
                                                article.last_publication_date,
                                            ).format("DD.MM.YYYY")}
                                            title={article.data.category}
                                            headline={article.data.title}
                                            image={
                                                <GatsbyImage
                                                    alt={""}
                                                    // @ts-ignore
                                                    image={getImage(
                                                        article.data
                                                            .featured_image
                                                            .localFile
                                                            .childImageSharp
                                                            .gatsbyImageData,
                                                    )}
                                                    loading="eager"
                                                />
                                            }
                                            imagePosition={
                                                TeaserImagePosition.Right
                                            }
                                            size={TeaserSize.Default}
                                            withPointer={true}
                                            headlineSize={
                                                TeaserHeadlineSize.Default
                                            }
                                            teaserTextProps={{
                                                wrapHeadlineInLink: true,
                                            }}
                                        />
                                    </Link>
                                );
                            })}
                        </Section>

                        {data.prismicPages.nodes.length > 3 &&
                            [data.prismicPages.nodes[2]].map(
                                (article, index) => {
                                    const sectionType = SectionType.Ribbon;

                                    const image = (
                                        <GatsbyImage
                                            alt={""}
                                            // @ts-ignore
                                            image={getImage(
                                                article.data.featured_image
                                                    .localFile.childImageSharp
                                                    .gatsbyImageData,
                                            )}
                                            loading="eager"
                                        />
                                    );

                                    return (
                                        <Section
                                            type={sectionType}
                                            headline={"Trends"}
                                            headlinePosition={
                                                [0, 2].includes(index)
                                                    ? SectionHeadlinePosition.Right
                                                    : SectionHeadlinePosition.Left
                                            }
                                            showCloudAnimation={
                                                index % 2 === 0 ? false : true
                                            }
                                            showWarpAnimation={
                                                index % 2 === 0 ? false : true
                                            }
                                            warpStyles={{
                                                transform:
                                                    "rotate(-40deg) scale(1.6)",
                                                left: "-150px",
                                                opacity: 0.5,
                                                bottom: "0px",
                                                zIndex: 1,
                                            }}
                                            cloudStyles={{
                                                right: "-230px",
                                                top: "100px",
                                                transform: "scale(1.5)",
                                            }}
                                            key={index}
                                        >
                                            <Link
                                                to={article.uid}
                                                className="noStyles"
                                            >
                                                <Teaser
                                                    date={dayjs(
                                                        article.last_publication_date,
                                                    ).format("DD.MM.YYYY")}
                                                    title={
                                                        article.data.category
                                                    }
                                                    headline={
                                                        article.data.title
                                                    }
                                                    image={image}
                                                    imagePosition={
                                                        index % 2 === 0
                                                            ? TeaserImagePosition.Right
                                                            : TeaserImagePosition.Left
                                                    }
                                                    size={TeaserSize.Full}
                                                    withPointer={true}
                                                    headlineSize={
                                                        TeaserHeadlineSize.Large
                                                    }
                                                    teaserTextProps={{
                                                        wrapHeadlineInLink:
                                                            true,
                                                    }}
                                                />
                                            </Link>
                                        </Section>
                                    );
                                },
                            )}
                    </>
                )}

                <Pager pageContext={pageContext} />
            </div>
        </Layout>
    );
};

export const data = graphql`
    query PrismicPages {
        prismicPages: allPrismicArticle {
            nodes {
                data {
                    title
                    category
                    author
                    content {
                        text {
                            richText
                        }
                        image {
                            localFile {
                                ...constrainedImage
                            }
                        }
                    }
                    featured_image {
                        localFile {
                            ...fullSizeImage
                        }
                    }
                }
                _previewable
                last_publication_date
                uid
            }
        }
    }
`;

export default BlogIndex;
