import React from "react";
import { Link } from "gatsby";
import { Button } from "@alphaneun/ui-library";

interface PagerProps {
    pageContext: {
        previousPagePath: string;
        nextPagePath: string;
    };
}

const Pager: React.FC<PagerProps> = ({ pageContext }) => {
    const { previousPagePath, nextPagePath } = pageContext;

    return (
        <nav>
            <div>
                {previousPagePath && (
                    <Link to={previousPagePath}>
                        <Button>← Newer Posts</Button>
                    </Link>
                )}
            </div>

            <div>
                {nextPagePath && (
                    <Link to={nextPagePath}>
                        <Button>Older Posts →</Button>
                    </Link>
                )}
            </div>
        </nav>
    );
};

export default Pager;
